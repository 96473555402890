<!-- Testimonial Start -->
<section class="iq-testimonial-section light-gray-bg ">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="iq-testimonial text-center iq-testimonial-7">
                    <div class="owl-carousel" data-dots="false" data-nav="false" data-items="3" data-items-laptop="3" data-items-tab="2" data-items-mobile="1" data-items-mobile-sm="1" data-autoplay="true" data-loop="true" data-margin="30">
                        <div *ngFor="let list of List" class="iq-testimonial-info">
                            <div class="iq-testimonial-content">
                                <p>{{ list.desc }}</p>
                                <div class="iq-testimonial-avtar">
                                    <img alt="image-testimonial" class="img-fluid center-block" src="{{ list.image }}">
                                </div>
                            </div>
                            <div class="iq-testimonial-member">
                                <div class="avtar-name">
                                    <div class="iq-lead">{{ list.name }}</div>
                                    <span class="iq-post-meta">{{ list.design }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
