<p class="rs-p-wp-fix"></p>
    <rs-module-wrap id="rev_slider_3_1_wrapper" data-alias="qloud3" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
        <rs-module id="rev_slider_3_1" style="display:none;" data-version="6.1.8">
            <rs-slides>
                <rs-slide data-key="rs-3" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                    <img src="assets/revslider/assets/qbanner1.png" alt="QLOUD" title="qbanner1" width="1600" height="867" data-parallax="off" class="rev-slidebg" data-no-retina>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-0" class="textgyreadventor" data-type="text" data-color="#142149" data-rsp_ch="on" data-xy="xo:135px,96px,72px,15px;y:m;yo:-120px,-85px,-187px,-198px;" data-text="w:normal;s:60,42,36,36;l:80,56,50,45;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style="z-index:8;">Extending <span class="bluetext">Our Cloud<br>Security</span> Leadership
                    </rs-layer>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-1" data-type="text" data-color="#5e7290" data-rsp_ch="on" data-xy="xo:135px,96px,72px,21px;y:m;yo:40px,28px,-78px,-97px;" data-text="w:normal;s:16,11,18,12;l:32,22,30,24;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:900;sp:1000;sR:900;" data-frame_999="o:0;st:w;sR:7100;" style="z-index:9;">It is a long established fact that a reader will be distracted by the readable content of
                        <br>a page when looking at its layout.
                    </rs-layer>
                    <!--
      --><a id="slider-3-slide-3-layer-2" class="rs-layer iq-btn-round iq-button rev-btn" href="#" target="_self" rel="nofollow" data-type="button" data-rsp_ch="on" data-xy="xo:135px,96px,72px,24px;y:m;yo:163px,115px,14px,-11px;" data-text="w:normal;s:18,16,16,16;l:18,16,20,20;" data-dim="h:50px;minh:0px,none,none,none;" data-padding="t:15,15,0,15;r:30;b:15,15,0,15;l:30;" data-border="bor:10px,10px,10px,10px;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:1200;sp:1000;sR:1200;" data-frame_999="o:0;st:w;sR:6800;" data-frame_hover="bgc:#0d1e67;bor:10px,10px,10px,10px;sp:100;e:Power1.easeInOut;bri:120%;" style="z-index:10;background-color:#007bfc;">Read More
      </a>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-3" class="rs-pxl-1" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:-146px,-36px,73px,7px;y:m;yo:-27px,67px,84px,81px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:643px,388px,310px,248px;h:477px,288px,271px,184px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="st:2000;sp:1000;sR:2000;" data-frame_999="o:0;st:w;sR:6000;" style="z-index:12;"><img src="assets/revslider/assets/b1.png" alt="qloud" width="643" height="477" data-no-retina>
                    </rs-layer>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-4" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:60px,42px,195px,41px;y:b;yo:89px,63px,9px,20px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:624px,443px,350px,370px;h:346px,246px,190px,201px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="st:2500;sp:1000;sR:2500;" data-frame_999="o:0;st:w;sR:5500;" style="z-index:11;"><img src="assets/revslider/assets/b2.png" alt="qloud" width="624" height="346" data-no-retina>
                    </rs-layer>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-5" data-type="text" data-color="#142149" data-rsp_ch="on" data-xy="xo:320px,250px,235px,184px;y:m;yo:163px,115px,15px,-4px;" data-text="w:normal;s:20,16,16,9;l:50,50,37,22;fw:400,500,500,500;" data-vbility="t,t,t,f" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:1600;sp:1000;sR:1600;" data-frame_999="o:0;st:w;sR:6400;" style="z-index:13;"><a class="popup-youtube " href="https://www.youtube.com/watch?v=8AZ8GqW5iak"><i class="fas fa-play mr-2 bluetext"></i> Watch Video</a>
                    </rs-layer>
                    <!--
      -->
                    <rs-layer id="slider-3-slide-3-layer-6" data-type="text" data-color="#142149" data-rsp_ch="on" data-xy="xo:135px,90px,76px,46px;y:b;yo:150px,170px,222px,136px;" data-text="w:normal;s:18,12,9,5;l:25,17,12,7;" data-dim="h:auto,20px,15px,9px;" data-vbility="t,t,f,f" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:2200;sp:1000;sR:2200;" data-frame_999="o:0;st:w;sR:5800;" style="z-index:14;"><b>Free 14-Day Trial -</b> Join over 50,000 websites already using
                    </rs-layer>
                    <!--
      -->
                </rs-slide>
            </rs-slides>
            <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
        </rs-module>
        <script>
            if (typeof revslider_showDoubleJqueryError === "undefined") {
                function revslider_showDoubleJqueryError(sliderID) {
                    var err = "<div class='rs_error_message_box'>";
                    err += "<div class='rs_error_message_oops'>Oops...</div>";
                    err += "<div class='rs_error_message_content'>";
                    err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                    err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                    err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
                    err += "</div>";
                    err += "</div>";
                    jQuery(sliderID).show().html(err);
                }
            }
        </script>
    </rs-module-wrap>
