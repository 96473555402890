<p class="rs-p-wp-fix"></p>
<rs-module-wrap id="rev_slider_2_1_wrapper" data-alias="qloud2" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
    <rs-module id="rev_slider_2_1" style="display:none;" data-version="6.1.8">
        <rs-slides>
            <rs-slide data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                <img src="./assets/revslider/assets/slider2.png" alt="qloud" title="slider2" width="1178" height="845" data-parallax="off" class="rev-slidebg" data-no-retina>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-0" class="textgyreadventor" data-type="text" data-color="#007bfc" data-rsp_ch="on" data-xy="xo:136px,96px,67px,41px;y:m;yo:-119px,-100px,-274px,-254px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style="z-index:8;">Qloud App
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-1" class="textgyreadventor" data-type="text" data-color="#142149" data-rsp_ch="on" data-xy="xo:137px,97px,67px,41px;yo:315px,230px,108px,105px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:800;sp:1000;sR:800;" data-frame_999="o:0;st:w;sR:7200;" style="z-index:9;">Storage Service
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-2" data-type="text" data-color="#5e7290" data-rsp_ch="on" data-xy="xo:137px,97px,65px,40px;yo:448px,329px,185px,163px;" data-text="w:normal;s:20,14,20,12;l:35,24,35,24;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:1000;sp:1000;sR:1000;" data-frame_999="o:0;st:w;sR:7000;" style="z-index:10;">It Is a Long established fact that a reader will be distracted by the
                    <br>readable content of a page when looking at its layout.
                </rs-layer>
                <!--
    --><a id="slider-2-slide-2-layer-3" class="rs-layer iq-button iq-btn-round rev-btn" href="#" target="_self" rel="nofollow" data-type="button" data-rsp_ch="on" data-xy="xo:130px,92px,69px,42px;yo:582px,430px,290px,247px;" data-text="w:normal;s:18,12,20,16;l:18,12,24,16;" data-dim="h:50px,35px,50px,50px;minh:0px,none,none,none;" data-padding="t:15,11,15,15;r:30,21,30,25;b:15,11,15,15;l:30,21,30,25;" data-border="bor:10px,10px,10px,10px;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:1300;sp:1000;sR:1300;" data-frame_999="o:0;st:w;sR:6700;" data-frame_hover="bgc:#007aff;bor:10px,10px,10px,10px;sp:100;e:Power1.easeInOut;bri:120%;" style="z-index:11;background-color:#007bfc;text-transform:capitalize;">Start a Free Trial
    </a>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-4" data-type="image" data-rsp_ch="on" data-xy="x:r,r,r,c;xo:-45px,-21px,140px,0;y:b;yo:67px,80px,26px,21px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:614px,436px,389px,268px;h:639px,454px,405px,279px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="st:1600;sp:1000;sR:1600;" data-frame_999="o:0;st:w;sR:6400;" style="z-index:31;"><img src="./assets/revslider/assets/slider24.png" alt="qloud" width="614" height="639" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-5" class="rs-pxl-1" data-type="image" data-rsp_ch="on" data-xy="xo:897px,637px,278px,128px;yo:670px,476px,643px,600px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:120px,85px,64px,39px;h:103px,73px,55px,33px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2000;sp:1000;sR:2000;" data-frame_999="o:0;st:w;sR:6000;" data-loop_0="x:-5px;" data-loop_999="x:5px;sp:3000;e:Sine.easeInOut;yym:t;" style="z-index:13;"><img src="./assets/revslider/assets/slider21.png" alt="qloud" width="120" height="103" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-6" class="rs-pxl-1" data-type="image" data-rsp_ch="on" data-xy="xo:792px,563px,198px,89px;yo:595px,423px,595px,577px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:120px,85px,64px,39px;h:103px,73px,55px,33px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2000;sp:1000;sR:2000;" data-frame_999="o:0;st:w;sR:6000;" data-loop_0="x:-5px;" data-loop_999="x:5px;sp:3000;e:Sine.easeInOut;yym:t;" style="z-index:12;"><img src="./assets/revslider/assets/slider21.png" alt="qloud" width="120" height="103" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-7" class="rs-pxl-1" data-type="image" data-rsp_ch="on" data-xy="xo:1084px,770px,388px,202px;yo:593px,421px,600px,578px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:104px,73px,55px,33px;h:90px,64px,48px,29px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" data-loop_0="y:-5px;" data-loop_999="y:5px;sp:5000;e:Sine.easeInOut;yym:t;" style="z-index:32;"><img src="./assets/revslider/assets/slider23.png" alt="qloud" width="104" height="90" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-8" class="rs-pxl-1" data-type="image" data-rsp_ch="on" data-xy="xo:920px,654px,277px,122px;yo:498px,354px,536px,540px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:104px,73px,55px,33px;h:90px,64px,48px,29px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" data-loop_0="y:-5px;" data-loop_999="y:5px;sp:5000;e:Sine.easeInOut;yym:t;" style="z-index:33;"><img src="./assets/revslider/assets/slider23.png" alt="qloud" width="104" height="90" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-9" class="rs-pxl-2" data-type="image" data-rsp_ch="on" data-xy="xo:1279px,909px,529px,320px;yo:675px,480px,640px,597px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:89px,63px,47px,28px;h:76px,54px,41px,25px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2300;sp:1000;sR:2300;" data-frame_999="o:0;st:w;sR:5700;" data-loop_999="sX:0.8;sY:0.8;sp:5000;e:Power0.easeInOut;yys:t;yyf:t;" style="z-index:14;"><img src="./assets/revslider/assets/slider22.png" alt="qloud" width="89" height="76" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-10" class="rs-pxl-2" data-type="image" data-rsp_ch="on" data-xy="xo:842px,598px,213px,108px;yo:396px,281px,470px,478px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:89px,63px,47px,28px;h:76px,54px,41px,25px;" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2300;sp:1000;sR:2300;" data-frame_999="o:0;st:w;sR:5700;" data-loop_999="sX:0.8;sY:0.8;sp:5000;e:Power0.easeInOut;yys:t;yyf:t;" style="z-index:15;"><img src="./assets/revslider/assets/slider22.png" alt="qloud" width="89" height="76" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-11" data-type="image" data-rsp_ch="on" data-xy="xo:1338px,951px,561px,346px;yo:418px,297px,478px,294px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2600;sp:1000;sR:2600;" data-frame_999="o:0;st:w;sR:5400;" style="z-index:26;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-12" data-type="image" data-rsp_ch="on" data-xy="xo:1230px,874px,505px,311px;yo:353px,251px,441px,272px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2600;sp:1000;sR:2600;" data-frame_999="o:0;st:w;sR:5400;" style="z-index:16;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-14" data-type="image" data-rsp_ch="on" data-xy="xo:1338px,951px,561px,346px;yo:384px,273px,460px,283px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2900;sp:1000;sR:2900;" data-frame_999="o:0;st:w;sR:5100;" style="z-index:27;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-15" data-type="image" data-rsp_ch="on" data-xy="xo:1284px,913px,533px,328px;yo:316px,224px,423px,260px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3200;sp:1000;sR:3200;" data-frame_999="o:0;st:w;sR:4800;" style="z-index:23;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-17" data-type="image" data-rsp_ch="on" data-xy="xo:1338px,951px,561px,346px;yo:314px,223px,421px,259px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:56px,39px,29px,17px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3800;sp:1000;sR:3800;" data-frame_999="o:0;st:w;sR:4200;" style="z-index:30;"><img src="./assets/revslider/assets/slider27.png" alt="qloud" width="97" height="56" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-18" data-type="image" data-rsp_ch="on" data-xy="xo:1284px,913px,533px,328px;yo:350px,248px,441px,272px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2900;sp:1000;sR:2900;" data-frame_999="o:0;st:w;sR:5100;" style="z-index:22;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-20" data-type="image" data-rsp_ch="on" data-xy="xo:1285px,913px,533px,328px;yo:385px,273px,459px,283px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2600;sp:1000;sR:2600;" data-frame_999="o:0;st:w;sR:5400;" style="z-index:21;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-21" data-type="image" data-rsp_ch="on" data-xy="xo:1230px,874px,504px,310px;yo:320px,227px,423px,260px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:2900;sp:1000;sR:2900;" data-frame_999="o:0;st:w;sR:5100;" style="z-index:17;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-22" data-type="image" data-rsp_ch="on" data-xy="xo:1229px,873px,504px,310px;yo:253px,179px,387px,238px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3500;sp:1000;sR:3500;" data-frame_999="o:0;st:w;sR:4500;" style="z-index:19;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-23" data-type="image" data-rsp_ch="on" data-xy="xo:1229px,873px,504px,310px;yo:287px,204px,405px,249px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3200;sp:1000;sR:3200;" data-frame_999="o:0;st:w;sR:4800;" style="z-index:18;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-24" data-type="image" data-rsp_ch="on" data-xy="xo:1226px,871px,503px,310px;yo:253px,179px,388px,239px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:56px,39px,29px,17px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3800;sp:1000;sR:3800;" data-frame_999="o:0;st:w;sR:4200;" style="z-index:20;"><img src="./assets/revslider/assets/slider27.png" alt="qloud" width="97" height="56" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-25" data-type="image" data-rsp_ch="on" data-xy="xo:1284px,913px,532px,328px;yo:283px,201px,404px,249px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:56px,39px,29px,17px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3800;sp:1000;sR:3800;" data-frame_999="o:0;st:w;sR:4200;" style="z-index:25;"><img src="./assets/revslider/assets/slider27.png" alt="qloud" width="97" height="56" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-26" data-type="image" data-rsp_ch="on" data-xy="xo:1284px,913px,533px,328px;yo:282px,200px,405px,249px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3500;sp:1000;sR:3500;" data-frame_999="o:0;st:w;sR:4500;" style="z-index:24;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-27" data-type="image" data-rsp_ch="on" data-xy="xo:1338px,951px,561px,346px;yo:350px,248px,442px,272px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3200;sp:1000;sR:3200;" data-frame_999="o:0;st:w;sR:4800;" style="z-index:28;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
                <rs-layer id="slider-2-slide-2-layer-28" data-type="image" data-rsp_ch="on" data-xy="xo:1338px,951px,560px,345px;yo:316px,224px,423px,260px;" data-text="w:normal;s:20,14,10,6;l:0,17,12,7;" data-dim="w:97px,68px,51px,31px;h:89px,63px,47px,28px;" data-vbility="t,t,t,f" data-frame_0="y:-50,-35,-26,-16;" data-frame_1="st:3500;sp:1000;sR:3500;" data-frame_999="o:0;st:w;sR:4500;" style="z-index:29;"><img src="./assets/revslider/assets/slider25.png" alt="qloud" width="97" height="89" data-no-retina>
                </rs-layer>
                <!--
    -->
            </rs-slide>
        </rs-slides>
        <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
    </rs-module>
    <script>
        if (typeof revslider_showDoubleJqueryError === "undefined") {
            function revslider_showDoubleJqueryError(sliderID) {
                var err = "<div class='rs_error_message_box'>";
                err += "<div class='rs_error_message_oops'>Oops...</div>";
                err += "<div class='rs_error_message_content'>";
                err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
                err += "</div>";
                err += "</div>";
                jQuery(sliderID).show().html(err);
            }
        }
    </script>
</rs-module-wrap>
