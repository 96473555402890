import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: []
})
export class IconBoxComponent implements OnInit {

  items: any[] = [
    {
      title: 'INCREDIBLE PERFORMANCE',
      description: 'Handle even your most resource-intensive projects with ease. Our cloud servers are located in our own state-of-the-art data centers. You get best-in-class performance with AMD EPYC 2nd Gen, Intel® Xeon® Gold processors and speedy NVMe SSDs.',
      image: 'assets/images/icon-box/01.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'GOOD TO GO IN SECONDS',
      description: 'Get started without waiting! With our friendly interface, you can create server instances almost instantly, usually in under 10 seconds.',
      image: 'assets/images/icon-box/02.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'LOAD BALANCER',
      description: 'Load Balancers help you scale your applications easily by automatically distributing traffic across your infrastructure. Handle common use-cases like TLS termination or create an internet-facing entry point to route traffic into your Cloud.',
      image: 'assets/images/icon-box/03.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'NETWORKS',
      description: 'Let your servers communicate through a private network and setup complex network topologies. Ideal for running a kubernetes cluster or a database server that should not be reachable publicly.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'FIREWALLS',
      description: 'Our stateful firewalls make it easy to secure your infrastructure at no additional cost. Define inbound and outbound rules to deny any unwanted traffic. Once configured, you can assign them to as many of your cloud servers as you like.',
      image: 'assets/images/icon-box/05.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'VOLUMES',
      description: 'Volumes offer highly available and reliable SSD storage for your cloud servers. You can expand each Volume to up to 10 TB at any time and you can connect them to your cloud servers.',
      image: 'assets/images/icon-box/06.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'PERFORMANCE',
      description: 'AMD EPYC 2nd Gen and Intel® Xeon® Gold processors together with speedy NVMe SSDs mean you’ll profit from high performance hardware. You can choose between local storage and network storage (NVMe SSD RAID or Ceph). And you’ll benefit from our redundant 10 Gbit network connection.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'DOCUMENTATION & API',
      description: 'There is a wide variety of features at your fingertips to help you manage your cloud infrastructure. We also provide a REST-API and a CLI tool for developers. Our thorough documentation, together with programming examples, make it easy to get started and make sure you’ll get the most out of your instances.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'SNAPSHOTS',
      description: 'With our snapshot feature, you can make manual backups of your servers. You can use our snapshots to restore your server to a saved image, use saved images to create new cloud servers, or transfer images during a project.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'BACKUPS',
      description: 'Backups are copies of your server that we make automatically to keep your data safe. You can store up to 7 of them.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'FLOATING IPS',
      description: 'Assign floating IPs to fit your needs. Use them on a redundant server or for a highly available server cluster.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'TRAFFIC',
      description: 'With 20 TB of included traffic, you’ll have lots of bandwidth for your projects, regardless of which Cloud package you choose.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'DDOS PROTECTION',
      description: 'Hetzner Online will safeguard your Hetzner cloud servers using the latest hardware appliances and sophisticated perimeter security technologies, providing you with first-rate protection against large-scale DDoS attacks. And all that free of charge.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    },
    {
      title: 'DATA PROTECTION',
      description: 'GDPR compliancy for your Cloud. You can create a Data Processing Agreement (DPA) online that will be in accordance with Article 28 of the GDPR (General Data Protection Regulation). You can even enter the correct European location for your DPA.',
      image: 'assets/images/icon-box/04.png',
      href: 'javascript:void(0)'
    }

  ];
  constructor() { }

  ngOnInit(): void {
  }

}
