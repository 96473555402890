import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html'
})
export class ContactCardComponent implements OnInit {

  List: any[] = [
    // { title: 'Location', icon: 'ion ion-ios-location' , desc: '1234 North Avenue Luke Lane, South Bend, IN 360001'},
    { title: 'General Queries', icon: 'ion ion-ios-email' , desc: 'cloud@vaasits.com'},
    { title: 'Technical Support Queries', icon: 'ion ion-ios-email' , desc: 'cloudsupport@vaasits.com'},
    { title: 'Payment Queries', icon: 'ion ion-ios-email' , desc: 'cloudpayments@vaasits.com'},
    // { title: 'Phone', icon: 'ion ion-ios-telephone' , desc: '+91 123 456 7890'},
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
