<section class="pt-0 iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let list of List; let i = index" class="col-lg-4 col-md-6 col-md-12 mb-lg-0 mb-5">
                <div class="iq-icon-box iq-icon-box-style-8 text-center wow fadeInUp" data-wow-duration="0.6s">
                    <div class="icon-box-img">
                        <img src="{{ list.image }}" class="img-fluid" title="{{ i }}" alt="qloud">
                    </div>
                    <div class="icon-box-content">
                        <h5 class="icon-box-title"> <a [routerLink]="[list.link]">{{ list.title }}</a> </h5>
                        <p class="icon-box-desc">{{ list.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
