<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START REVOLUTION SLIDER -->
<app-revolution-slider1></app-revolution-slider1>
<!-- END REVOLUTION SLIDER -->
<!-- Features Start -->
<app-section-one></app-section-one>
<!-- Features End -->
<!-- Counter Start -->
<app-section-vertical-two></app-section-vertical-two>
<!-- Counter End -->
<!-- Service Tab Start -->
<app-nav-tabs></app-nav-tabs>
<!-- Service Tab End -->
<!-- Pricing Start -->
<app-pricing-plan></app-pricing-plan>
<!-- Pricing End -->
<!-- Icon-box Start -->
<app-icon-box></app-icon-box>
<!-- Icon-box End -->
<!-- Your Application Start -->
<app-your-application></app-your-application>
<!-- Your Application End -->
<!-- Testimonial Start -->
<app-our-testimonial></app-our-testimonial>
<!-- Testimonial End -->
<!-- Blog Start -->
<app-our-blog></app-our-blog>
<!-- Blog End -->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->