<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START REVOLUTION SLIDER -->
<app-revolution-slider3></app-revolution-slider3>
<!-- END REVOLUTION SLIDER -->
    <!-- Main-Content Start -->
    <div class="main-content">
        <!-- Icon-box Start -->
        <app-icon-box></app-icon-box>
        <!-- Icon-box End -->
        <!-- About Us Start -->
        <app-about-us></app-about-us>
        <!-- About Us End -->
        <!-- Process Start-->
        <app-process></app-process>
        <!-- Process End-->
        <!-- Security Start-->
        <app-security></app-security>
        <!-- Security End-->
        <!-- Features Start -->
        <app-features></app-features>
        <!-- Features End -->
        <!-- Client Start -->
        <app-our-client></app-our-client>
        <!-- Client End -->
        <!-- Testimonial Start -->
        <app-testimonial></app-testimonial>
        <!-- Testimonial End -->
        <!-- Contact Start -->
        <app-contact></app-contact>
        <!-- Contact End -->
        <!-- Blog Start -->
        <app-our-blog></app-our-blog>
        <!-- Blog End -->
    </div>
    <!-- Footer start -->
    <app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
    <!-- Footer end -->
