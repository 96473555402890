const topMenuBarItems = [{
        href: '#iq-home',
        title: 'Home',
        isCurrent: true,
        submenu: [{
                href: '/landing-page1',
                title: 'Home One'
            },
            // {
            //     href: '/landing-page2',
            //     title: 'Home Two'
            // },
            // {
            //     href: '/landing-page3',
            //     title: 'Home Three'
            // },
            // {
            //     href: '/cloud-hosting',
            //     title: 'Cloud Hosting'
            // },
            {
                href: '/cloud-analytics',
                title: 'Cloud Analytics'
            },
            {
                href: '/cloud-databases',
                title: 'Cloud Databases'
            },
            {
                href: '/cloud-media-services',
                title: 'Cloud Media Service'
            },
            {
                href: '/cloud-compute',
                title: 'Cloud Compute'
            }
        ]
    },
    {
        href: '#iq-about',
        title: 'About Us ',
        submenu: [{
                href: '/about-us-1',
                title: 'About Us One'
            },
            // {
            //     href: '/about-us-2',
            //     title: 'About Us Two'
            // }
        ]
    },
    {
        href: '#iq-pages',
        title: 'Pages',
        submenu: [{
                href: '/services',
                title: 'Services'
            },
            // {
            //     href: '/our-team',
            //     title: 'Our Team'
            // },
            {
                href: '/pricing-plan',
                title: 'Pricing Plan'
            },
            // {
            //     href: '/clients',
            //     title: 'Clients'
            // },
            {
                href: '/faq',
                title: 'FAQ'
            },
            {
                href: '/error-404',
                title: 'Error 404'
            }

        ]
    },
    // {
    //     href: '#iq-portfolio',
    //     title: 'Portfolio',
    //     submenu: [{
    //             href: '/portfolio-2-columns',
    //             title: 'Portfolio 2 Columns'
    //         },
    //         {
    //             href: '/portfolio-3-columns',
    //             title: 'Portfolio 3 Columns'
    //         },
    //         {
    //             href: '/portfolio-4-columns',
    //             title: 'Portfolio 4 Columns'
    //         },
    //         {
    //             href: '/portfolio-details',
    //             title: 'Portfolio Details'
    //         }
    //     ]
    // },
    // {
    //     href: '#iq-blog',
    //     title: 'Blog',
    //     submenu: [{
    //             href: '/blog',
    //             title: 'Blog'
    //         },
    //         {
    //             href: '/blog-two-column',
    //             title: 'Two Column Blog'
    //         },
    //         {
    //             href: '/blog-three-column',
    //             title: 'Three Column Blog',
    //         },
    //         {
    //             href: '/blog-right-column',
    //             title: 'Right Column Blog',
    //         },
    //         {
    //             href: '/blog-detail',
    //             title: 'Blog Detail'
    //         }
    //     ]
    // },
    {
        href: '#iq-contact-us',
        title: 'Contact Us',
        submenu: [{
                href: '/contact-us-1',
                title: 'Contact Us 1',
            },
            {
                href: '/contact-us-2',
                title: 'Contact Us 2',
            }
        ]
    }
];


export { topMenuBarItems };