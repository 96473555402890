<section class="pt-0 iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-0 mb-5 align-self-center">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-btn-container mt-4">
                    <a class="iq-button iq-btn-medium iq-btn-round iq-btn-flat d-inline" href="#"> Read More</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div *ngFor="let list of List1" class="iq-process iq-process-step-style-1">
                            <div class="iq-process-step">
                                <div class="iq-step-content">
                                    <img class="hover-img img-fluid" src="{{ list.image }}" alt="fancybox">
                                </div>
                                <div class="iq-step-text-area">
                                    <h4 class="iq-step-title">{{ list.title }}</h4>
                                    <span class="iq-step-desc">{{ list.desc }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div  *ngFor="let list of List2" class="iq-process iq-process-step-style-1">
                            <div class="iq-process-step">
                                <div class="iq-step-content">
                                    <img class="hover-img img-fluid" src="{{ list.image }}" alt="fancybox">
                                </div>
                                <div class="iq-step-text-area">
                                    <h4 class="iq-step-title">{{ list.title }}</h4>
                                    <span class="iq-step-desc">{{ list.desc }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
